body {
  background: var(--brand-color);
  color: var(--primary-text-color);
  font-family: 'Inter', sans-serif;

  min-width: auto;
  min-height: 100vh;
  overflow: scroll;
}

.hot-summer-style {
  background-color: #457FE5;

  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/images/grain-bg.png');
  }
}

.furrends-giving-style {

  &::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../assets/images/grain-bg.png');
  }
}

@media only screen and (min-width: 1000px) {
  /* body {
    overflow: hidden;
  } */
}

::selection {
  color: var(--brand-color);
  background-color: var(--primary-text-color);
}

input::placeholder {
  color: var(--brand-color);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:after {
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

*:after {
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
}

/* Video container styles */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 2rem 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

button {
    background: none;
    box-shadow: none;
}

button {
  background: none;
  box-shadow: none;
}

.button {
  color: var(--primary-text-color);
  background-color: var(--brand-color);
  border-radius: 16px;
  padding: 18px 20px 20px;
  height: auto;
  text-align: center;

  transition: all 150ms ease-out;

  &:hover {
    background: var(--accent-color);
  }

  &:active {
    opacity: 0.9;
  }
}

input {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid var(--brand-color);
  text-align: center;
}

input:focus {
  border-color: var(--accent-color);
}

a.disabled {
  opacity: 0.3;
  pointer-events: none;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
}

a {
  color: var(--primary-text-color);
  text-decoration: none;
}

h1 {
  color: var(--primary-text-color);
  font-family: 'catalpaultrablack', sans-serif;
  font-size: 90px;
  line-height: 1;

  @media only screen and (max-width: 1000px) {
    font-size: 52px;
  }
}

h4 {
  color: var(--primary-text-color);
  font-family: 'catalpaultrablack', sans-serif;
  font-size: 24px;
  line-height: 1;
}

.icon {
  width: 44px;
  height: 44px;
  display: block;
  background-repeat: no-repeat;
}

.icon-blog {
  background-image: url('../assets/images/medium.svg?v1');
}
.icon-twitter {
  background-image: url('../assets/images/twitter.svg?v1');
}
.icon-discord {
  background-image: url('../assets/images/discord.svg?v1');
}
.icon-instagram {
  background-image: url('../assets/images/instagram.svg?v1');
}
.icon-medium {
  background-image: url('../assets/images/medium.svg?v1');
}
.icon-tiktok {
  background-image: url('../assets/images/tiktok.svg?v1');
}
.icon-youtube {
  background-image: url('../assets/images/youtube.svg?v1');
}

#video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  z-index: -1;
}

form label,
.sp-link-wrapper {
  display: none !important;
}

.sp-button {
  color: var(--primary-text-color);
  background-color: var(--brand-color);
  border-radius: 16px;
  padding: 18px 20px 20px !important;
  height: auto !important;
  text-align: center;
  font-size: 18px !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;

  transition: all 150ms ease-out;

  &:hover {
    background: var(--accent-color) !important;
    top: 0 !important;
  }

  &:active {
    opacity: 0.9;
  }
}

.sp-form-control {
  padding: 18px 20px 20px !important;
  height: auto !important;
  -webkit-appearance: textfield;
}

.sp-message-success {
  margin-top: 30px;

  h3 {
    font-weight: 700px;
  }
}

@keyframes clouds1 {
  0% {
    opacity: 0;
    transform: translateX(20px) translateY(20px) scale(0.9);
  }
  50% {
    opacity: 1;
    transform: translateX(0px) translateX(0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(-20px) translateY(-10px) scale(1.1);
  }
}

@keyframes clouds2 {
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.8);
  }
  50% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px) scale(1.2);
  }
}

@keyframes clouds3 {
  0% {
    opacity: 0;
    transform: translateX(-50px) translateY(5px) scale(0.9);
  }
  50% {
    opacity: 1;
    transform: translateX(0px) translateY(0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateX(50px) translateY(10px) scale(1.1);
  }
}

.clouds-bg {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  height: 100vh;
  /* max-width: 1280px; */
  /* margin: 0 auto; */
  background: url('../assets/images/clouds-bg.webp') no-repeat;
  background-position: 125% 0%;

  overflow: visible;

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: -100px;
    width: 1055px;
    height: 395px;
    background: url('../assets/images/clouds-bg.webp') no-repeat;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1055px;
    height: 395px;
    background: url('../assets/images/clouds-bg.webp') no-repeat;
  }
}

.hot-summer-style {
  .clouds-bg {
    background-image: url('../assets/images/hot-summer_clouds-bg.png');

    &::before {
      background-image: url('../assets/images/hot-summer_clouds-bg.png');
    }
  
    &::after {
      background-image: url('../assets/images/hot-summer_clouds-bg.png');
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  padding: 16px;
  position: relative;
  z-index: 20;
  max-width: 1184px;
  margin: 0 auto;
}

.header-nav {
  display: none;
  font-size: 18px;
  font-weight: bold;

  a {
    border-bottom: 1px solid;
    transition: all 150ms ease-out;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  li {
    margin-right: 55px;
  }
}

@media only screen and (min-width: 600px) {
  .header-nav {
    display: flex;
  }
}

.content {
  max-width: 1184px;
  padding: 0 16px;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  > p {
    font-size: 20px;
    line-height: 32px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
}

@media only screen and (min-height: 600px) and (min-width: 1024px) {
  .content {
    margin-top: 5vw;
  }
}

.hero {
  max-width: 1000px;
  position: relative;
  left: -92px;

  @media only screen and (max-width: 1000px) {
    left: initial;
  }
}

.form {
  border-radius: 24px;
  background-color: #fff;
  color: var(--brand-color);
  padding: 14px;
  max-width: 100%;
  /* min-height: 264px; */
  pointer-events: auto;

  > h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }

  > p {
    margin-top: 4px;
    margin-bottom: 0px;
    font-size: 17px;
    line-height: 24px;
  }

  .sp-form-outer > div,
  .sp-form .sp-field {
    padding-left: 0;
    padding-right: 0;
  }
}

.sp-form-fields-wrapper {
  width: 100% !important;
}

@media only screen and (min-width: 1000px) {
  .form {
    max-width: 412px;
  }

  .sp-form-fields-wrapper {
    max-width: 440px;
  }
}

.parallax {
  user-select: none;
  max-width: 100%;
  height: min(100vw, 680px);
  overflow: hidden;
  margin: -100px auto 0;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  picture {
    width: 100%;
  }

  picture img {
    position: absolute;
    right: -200px;
    top: 50px;
    left: 0;
    width: 100%;
  }
}

@media only screen and (min-height: 600px) and (min-width: 1024px) {
  picture img {
    margin-top: 5vw;
  }
}

@media only screen and (min-width: 1000px) {
  .parallax {
    max-width: 1184px;
    margin: 0px auto;
    height: auto;
    position: absolute;
    overflow: visible;

    picture img {
      position: absolute;
      right: -290px;
      top: 40px;
      left: auto;
      width: 1024px;
    }
  }
}

.footer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 12px;
  max-width: 1184px;
  padding: 0 16px 56px;
  margin: 0 auto;
}

.footer__logo {
  margin-bottom: 32px;

  img + img {
    margin-left: 100px;

    @media only screen and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}

.hot-summer-style {
  .footer__continer {
    background-color: rgba(0,10,255,81%);
  }

  .icon {
    opacity: 0.7;
  }
}

.footer__continer {
  width: 100%;
  padding: 36px 40px;
  background: #3b322f;
  border-radius: 32px;
}

.footer__links {
  display: flex;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  > div + div {
    margin-left: 82px;

    @media only screen and (max-width: 1000px) {
      margin-left: 0;
      margin-top: 24px;
    }
  }

  ul {
    margin-top: 16px;

    li + li {
      margin-top: 16px;
    }
  }

  a {
    font-weight: 500;

    &:hover {
      color: var(--brand-color);
      border-radius: 4px;
    }
  }
}

.footer__socials ul {
  display: flex;

  li + li {
    margin: 0;
  }

  a:hover {
    opacity: 0.4;
  }
}

@media only screen and (min-width: 600px) {
  /* .footer {
    display: none;
  } */
}

.bubble {
  transition: all 100ms ease-out;
}

.bubble-1 {
  position: absolute;
  left: 35.5%;
  top: 90%;
  width: 65px;
  height: 65px;
}

.bubble-2 {
  position: absolute;
  left: 4%;
  top: 32%;
  width: 121px;
  height: 121px;
}

.bubble-3 {
  position: absolute;
  left: -2%;
  top: 71%;
  width: 65px;
  height: 65px;
}

.furr-sound {
  position: absolute;
  left: 76%;
  top: 41%;
  cursor: pointer;
  z-index: 9999;

  transition: all 300ms ease-out;
  transform-origin: 50% 50%;

  &:hover {
    transform: scale(1.2);
  }

  img {
    width: 60px;
    height: 60px;
  }
  img:nth-child(2) {
    display: none;
  }
}

.furr-sound.pause {
  img:nth-child(1) {
    display: none;
  }
  img:nth-child(2) {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .bubble-1 {
    left: 35.5%;
  }

  .bubble-2 {
    left: 58.8%;
    top: 22%;
    width: 190px;
    height: 190px;
  }

  .bubble-3 {
    left: -2%;
  }

  .furr-sound {
    left: 88%;
    top: 38%;

    margin-left: 70px;
    min-width: 125px;

    img {
      width: 121px;
      height: 121px;
    }
  }
}

.features {
  margin: 28px 0;
  position: relative;

  @media only screen and (max-width: 1000px) {
    margin-top: -50px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 150px;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 32px;
    z-index: -1;

    @media only screen and (max-width: 1000px) {
      top: 30px;
    }
  }

  img,
  svg {
    margin: 0 -100px -170px -147px;
    max-width: initial;

    @media only screen and (max-width: 1000px) {
      max-width: 100%;
      margin: 0 0 -40px 0px;
    }
  }
}

.coming-soon {
  margin: 28px 0;
  position: relative;
}

.subs {
  margin: 28px 0;
  position: relative;

  background: url('../assets/images/subscribe-bg.webp') no-repeat;
  background-size: cover;

  mask-image: url('../assets/images/subs.png');
  mask-repeat: no-repeat;
  mask-position: center;

  border-radius: 32px;

  overflow: hidden;

  padding-bottom: 70px;

  @media only screen and (max-width: 1000px) {
    padding-bottom: 22px;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.subs__container {
  padding-top: 56px;
  padding-left: 64px;
  padding-right: 64px;

  @media only screen and (max-width: 1000px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
  }

  div {
    color: var(--primary-text-color);
    font-family: 'catalpaultrablack', sans-serif;
    font-size: 62px;
    line-height: 1;
    margin-bottom: 16px;

    @media only screen and (max-width: 1000px) {
      margin-bottom: 8px;
      font-size: 28px;
    }
  }

  p {
    font-size: 24px;

    @media only screen and (max-width: 1000px) {
      font-size: 16px;
    }
  }

  button {
    position: relative;
    color: var(--brand-color);
    background: #fff;
    border-radius: 999px;
    padding: 25px 32px;
    margin-top: 32px;
    font-family: 'catalpaultrablack', sans-serif;
    font-size: 24px;

    &:hover {
      color: #fff;
      background: var(--brand-color);
    }

    @media only screen and (max-width: 1000px) {
      padding: 16px;
      font-size: 20px;
      width: 100%;
      text-align: center;
    }
  }
}

.widget {
  input {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    margin-right: 16px;
    min-width: 220px;
  }

  button {
    border-top: 1px solid var(--brand-color);
    border-bottom: 1px solid var(--brand-color);
    border-radius: 0.375rem;
    background-color: var(--brand-color) !important;
    color: #fff !important;
  }
}

.modal-subs__close {
  position: absolute;
  top: 10px;
  right: 16px;
  font-family: 'catalpaultrablack', sans-serif;
  font-size: 18px;
  color: var(--brand-color);

  &:hover {
    opacity: 0.8;
  }
}

.modal-subs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    color: var(--brand-color);
    padding: 0.5rem 0;
    margin-bottom: 8px;
  }

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;

  &.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

.modal-subs__overlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
}

.modal-subs__container {
  position: relative;
  padding: 16px 24px 24px;
  background: #fff;
  color: var(--brand-color);
  border-radius: 16px;
  min-width: 358px;
  max-width: 90vw;

  p {
    color: var(--brand-color);
  }
}

.join-whitelist {
  margin-top: 16px;
  font-size: 24px;
  font-family: 'catalpaultrablack', sans-serif;
  color: var(--brand-color);
  background: #fff;
  padding: 23px 86px;
  border-radius: 72px;

  svg {
    margin-left: 16px;
    position: relative;
    top: 3px;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 22px;
    padding: 23px;
    width: 100%;
    text-align: center;
  }

  &:hover {
    background: var(--brand-color);
    color: #fff;
  }
}

.app-live {
  position: sticky;
  top: 16px;
  z-index: 1000;
  background: #fff;
  border-radius: 999px;
  color: var(--brand-color);
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  text-align: center;
  gap: 8px;
  opacity: 1;

  transition: all 0.3s ease;

  &.hidden {
    min-height: 0px;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  a {
    color: var(--brand-color);
    /* text-decoration: underline; */
    /* font-weight: 800; */
  }

  @media only screen and (max-width: 1000px) {
    gap: 0;
    overflow: overflow-wrap;
    padding: 10px 80px 10px 80px;
  }
}

.app-live__close {
  font-size: 26px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 24px;
  opacity: 0.5;

  &:hover {
    opacity: 0.9;
  }
}

.page404 {
  background: url('../assets/images/404.webp') no-repeat;
  background-size: cover;

  div {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    font-size: 150px;
    margin-bottom: 0.2em;
  }

  a {
    background: #fff;
    color: var(--brand-color);
    padding: 18px 24px;
    border-radius: 32px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.coming-soon-bubbles g:nth-child(1) image {
  animation: reveal 3s ease-out 10s;
}

.coming-soon-bubbles g:nth-child(2) image {
  animation: reveal 3s ease-out 16s;
}

.coming-soon-bubbles g:nth-child(3) image {
  animation: reveal 3s ease-out 21s;
}

.coming-soon-bubbles g:nth-child(4) image {
  animation: reveal 3s ease-out 26s;
}

.coming-soon-bubbles g:nth-child(5) image {
  animation: reveal 3s ease-out 32s;
}

.coming-soon-bubbles g:nth-child(6) image {
  animation: reveal 3s ease-out 36s;
}

.coming-soon-bubbles g:nth-child(7) image {
  animation: reveal 3s ease-out 42s;
}

#getWaitlistInnerContainer .gw-font-bold.gw-text-2xl.gw-pb-2.gw-text-center {
  display: none;
}

#getWaitlistContainer {
  & > div {
    padding: 0;
  }

  .gw-welcome {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    li {
      margin: 0.5em 0;
    }
  }

  .gw-social-label {
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }
}

.blogs {
  margin: 28px 0;
  position: relative;
  background-size: cover;
  background-color: #fff;
  color: var(--brand-color);

  mask-repeat: no-repeat;
  mask-position: center;

  border-radius: 32px;

  overflow: hidden;

  padding-bottom: 70px;

  @media only screen and (max-width: 1000px) {
    padding-bottom: 22px;
  }
}

.blogs__title {
  padding-top: 56px;
  padding-left: 64px;
  padding-right: 64px;

  color: var(--brand-color);

  @media only screen and (max-width: 1000px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
  }

  div {
    font-family: 'catalpaultrablack', sans-serif;
    font-size: 62px;
    line-height: 1;
    margin-bottom: 16px;
    color: var(--brand-color);

    @media only screen and (max-width: 1000px) {
      margin-bottom: 8px;
      font-size: 28px;
    }
  }

  p {
    font-size: 24px;

    @media only screen and (max-width: 1000px) {
      font-size: 16px;
    }
  }
}
/* Blog - Related Articles */
.blogs__related {
  padding-top: 56px;
  padding-left: 64px;
  padding-right: 64px;
  color: var(--brand-color);

  @media only screen and (max-width: 1000px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
  }

  p {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 1.6;

    @media only screen and (max-width: 1000px) {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 8px;
    }
  }
.article-link {
    margin-bottom: 16px;
  }
 
  a {
    color: var(--brand-color);
    text-decoration: none;
    display: block;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}
.article-image {
  margin: 32px 0;
  
  @media only screen and (max-width: 1000px) {
    margin: 24px 0;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .header-image {
  margin: 0 auto 40px;
  width: 100%;  /* Full width of the container */
  
  img {
    width: 100%;
    height: auto;
    border-radius: 16px;  /* Optional: adds slight rounding to corners */
  }

  @media only screen and (max-width: 1000px) {
    margin-bottom: 24px;
  }
}
  .image-credit a {
  color: #666666;
  text-decoration: underline;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
}
  .image-caption {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.4;
    color: #666666;

    .image-credit {
      display: block;
      margin-top: 4px;
      font-size: 12px;
    }
  }
}

.blogs__scroll__container {
  padding-top: 56px;
  margin-left: 60px;
  padding-left: 4px;

  margin-right: 60px;
  padding-right: 4px;

  color: var(--brand-color);

  @media only screen and (max-width: 759px) {
    margin-left: 16px;
    padding-left: 4px;

    margin-right: 16px;
    padding-right: 4px;

    padding-top: 16px;
  }

  @media only screen and (max-width: 1000px) and (min-width: 760px) {
    margin-left: 25px;
    padding-left: 4px;

    margin-right: 25px;
    padding-right: 4px;

    padding-top: 16px;
  }

  overflow-x: scroll;

  scrollbar-color: rgba(255, 77, 0, 0.2) rgb(255, 255, 255);
}

.blogs__scroll__container::-webkit-scrollbar {
  height: 6px;
}

.blogs__scroll__container::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: rgba(255, 77, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.blogs_content {
  min-width: fit-content;
  display: flex;
  gap: 24px;

  @media only screen and (max-width: 759px) {
    gap: 40px;
  }

  @media only screen and (max-width: 1000px) and (min-width: 760px) {
    gap: 50px;
  }
}

div.card {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 16px;
  color: #616161;
  width: 324px;
  height: 430px;
  border: 1px solid var(--brand-color);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;

  border-radius: 30px;
  display: block;
  padding: 0 20px;

  @media only screen and (max-width: 760px) {
    width: 300px;
    height: 430px;
  }

  @media only screen and (max-width: 1000px) and (min-width: 760px) {
    width: 310px;
    height: 430px;
  }

  img {
    display: block;
    margin-top: 20px;
    width: 100%;
  }

  div {
    margin-top: 25px;
    @media only screen and (max-width: 576px) {
      font-size: 18px;
    }
  }

  p {
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;
  }

  a {
    font-size: 18px;
    position: absolute;
    left: 20px;
    bottom: 20px;
    padding: 8px 12px;
    color: #ffffff;
    display: block;
    height: 46px;
    line-height: 30px;
    width: fit-content;
    border-radius: 23px;
    background-color: var(--brand-color);
  }
}

div.card:hover {
  box-shadow: 3px 2px 9px 0px rgb(255, 75, 0, 0.2), -1px 0px 10px 0px rgba(255, 77, 0, 0.098);
  transform: scale(1.02);
}

.menu {
  display: block;
  position: relative;
  cursor: pointer;
}

.menu-title {
  display: block;
  width: 100%;
  border-bottom: 1px solid;
  transition: all 150ms ease-out;
  &:hover {
    border-bottom-color: transparent;
  }
}

.menu-dropdown {
  margin-top: 10px;
  min-width: 100%;
  color: var(--brand-color);
  padding: 0;
  margin-right: 0;
  position: absolute;
  background: #ffffff;
  z-index: 100;
  transition: 0.5s padding;

  li {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;

    a {
      color: var(--brand-color);
      border-bottom-color: transparent;
    }
  }
}

.menu-dropdown > * {
  overflow: hidden;
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
  transition: 0.5s height cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s padding cubic-bezier(0.73, 0.32, 0.34, 1.5),
    0.5s margin cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s 0.2s color, 0.2s background-color;
}

.menu-dropdown > *:hover {
  background: rgba(0, 0, 0, 0.1);
}

.menu:not(:hover) > .menu-dropdown > * {
  visibility: hidden;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  transition: 0.5s 0.1s height, 0.5s 0.1s padding, 0.5s 0.1s margin, 0.3s color, 0.6s visibility;
  z-index: 99;
}

.report {
  max-width: 1184px;
  padding: 0;
  margin: 0 auto;
  position: relative;
  border-radius: 1rem;
}

.report_container {
  background-color: #fffcef;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;

  @media only screen and (min-width: 769px) {
    padding: 3rem;
  }
}

.report_content {
  text-align: start;
  margin: 0 auto;
  color: #3b322f;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.6rem;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;

  @media only screen and (min-width: 769px) {
    max-width: 56%;
    padding: 0;
  }

  strong {
    font-weight: 600;
    line-height: 1.6rem;
  }

  .blockquote {
    width: 100%;
    margin: 20px auto;
    padding: 2rem;
    background-color: #e8e1d0;
    border-radius: 1.5rem;
    color: #764135;
    font-size: 18px;
    line-height: 2rem;
    font-weight: 600;
    text-align: start;

    @media only screen and (min-width: 769px) {
      max-width: 80%;
      padding: 2rem;
      font-size: 20px;
    }

    .quotation {
      font-size: 40px;
      line-height: 1rem;
      font-weight: 600;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      width: 100%;
      color: #dd7339;
      display: block;
      font-family: Georgia, 'Times New Roman', Times, serif;
      word-wrap: normal;

      @media only screen and (min-width: 769px) {
        font-size: 60px;
      }
    }
  }

  a {
    color: var(--brand-color);
    text-decoration: underline;
  }

  .bolder {
    font-weight: 600;
  }

  .title {
    margin: 24px 0 12px 0;
    padding: 12px 0 0 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.8rem;
  }

  .sub_title {
    margin: 20px 0 0 0;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: #3b322f;
    line-height: 1.8rem;
  }

  p {
    padding: 15px 0;
    color: #3b322f;
  }

  ul {
    margin: 20px 0;
    color: #3b322f;
    li {
      margin-bottom: 10px;

      ul {
        padding-left: 2rem;
        margin-top: 0px;
        margin-bottom: 0px;
        li {
          padding: 0;
          line-height: 1.6rem;
          margin-bottom: 0px;
        }
      }

      span {
        display: inline-block;
        /* margin-bottom: 5px; */
      }
    }
  }
}

.text-center {
  text-align: center;
}

.pawty-button {
  display: inline-block;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 18px 32px;
  background: linear-gradient(89.88deg, #C4DFF2 -5.21%, #81D0FF 99.9%);
  border-radius: 72px;

  font-family: 'catalpaultrablack';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;

  text-align: center;
  letter-spacing: 0.01em;

  color: #FF85AA;
}
